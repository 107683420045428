
const state = {
    selectedClothes: {},
    clothesId:null
}

const mutations = {
    SET_SELECT_CLOTHES: (state, selectedClothes) => {
        state.selectedClothes = selectedClothes
    },
    setClothesId(state,clothesId){
        state.clothesId = clothesId
        console.log(state.clothesId)
    }
}
const actions = {
    setSelectedClothes({ commit },selectedClothes) {
        return new Promise(resolve => {
            console.log("122")
            commit('SET_SELECT_CLOTHES', selectedClothes)
            resolve()
        })
    },
    removeSelectedClothes({ commit }) {
        return new Promise(resolve => {
            commit('SET_SELECT_CLOTHES', {})
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
