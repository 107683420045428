/**
 * @description 导出vue/cli配置，以下所有配置修改需要重启项目
 */
 const Timestamp = new Date().getTime();
module.exports = {
    // 开发以及部署时的URL
    // hash模式时在不确定二级目录名称的情况下建议使用""代表相对路径或者"/二级目录/"
    // history模式默认使用"/"或者"/二级目录/"，记住只有hash时publicPath可以为空！！！
    publicPath: '/',
    // 生产环境构建文件的目录名
    outputDir: 'dist',
    output: {
        publicPath: '/',
        // 打包编译后的 文件名称  【模块名称.版本号.时间戳】
        filename: `[name].${Timestamp}.js`,
        chunkFilename: `[name].${Timestamp}.js`,
      },
    // 放置生成的静态资源 (js、css、img、fonts) 的 (相对于 outputDir 的) 目录。
    assetsDir: 'static',
    // 开发环境每次保存时是否输出为eslint编译警告
    lintOnSave: false,
    // 进行编译的依赖
    transpileDependencies: [],
    // 开发环境端口号
    devPort: 8020,
    // pwa
    pwa: true,
}
