import router from "../../router/router";
import store from "../../store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken } from "@/utils/auth";
import getPageTitle from "@/utils/get-page-title";
import { validatenull } from "@/utils/validate";
import { MessageBox } from "element-ui";

NProgress.configure({ showSpinner: false });

const whiteList = ["/login"];

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  document.title = getPageTitle(to.meta.title);
  if (getToken()) {
    if (to.path === "/login") {
      next("/");
      NProgress.done();
    } else {
      try {
        // next()
        const hasGetUserInfo = store.getters.userInfo;
        console.log(!validatenull(hasGetUserInfo))
        if (!validatenull(hasGetUserInfo)) {
          let bool = store.state.user.menuList.includes(to.path);
          if (!bool) {
            MessageBox.confirm("没有当前页面的权限，请跳转其他页面", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                NProgress.done();
              })
              .catch(() => {
                NProgress.done();
              });
          } else {
            next();
          }
        } else {
          await store.dispatch("user/getInfo");
          next();
        }
      } catch {
        await store.dispatch("user/logout");
        next("/login");
        NProgress.done();
      }
    }
  } else {
    if (
      whiteList.indexOf(to.path) !== -1 ||
      to.path.indexOf("/forget") !== -1
    ) {
      next();
    } else {
      // next(`/login?redirect=${to.path}`)
      next("/login");
      NProgress.done();
    }
  }
  // NProgress.done()
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
