<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style scoped lang="scss">
#app {
  font-family: ”Microsoft YaHei”, Arial, Helvetica, sans-serif,”宋体”;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  //color: #2c3e50;
  color: #303133;
  /*margin-top: 60px;*/

  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-user-select: text;
}
::v-deep .el-page-header__content {
  font-size: 20px;
}
#nprogress .bar {
  background: red !important; //自定义颜色
}
</style>
