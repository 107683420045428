import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store";
import "@/xyt";
import * as echarts from "echarts"; //引入echarts
import "./style/index.scss";
import "./assets/iconfonts/iconfont.css";

Vue.prototype.$echarts = echarts; //挂载在原型，组件内使用直接this.$echarts调用

Vue.prototype.$store = store;
Vue.config.productionTip = false;
Vue.directive("hidephone", {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function(el) {
    console.dir(el)
      el.innerText = el.innerText.slice(0, 3) + "****" + el.innerText.slice(7);

  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
