const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  userName: state => state.user.userName,
  storeName: state => state.user.storeName,
  userInfo: state => state.user.userInfo,
  phone: state => state.user.phone,
  enablePiecework: state => state.user.enablePiecework,
  selectedClothes: state => state.clothes.selectedClothes
}
export default getters
