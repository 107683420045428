import { login, logout, getInfo, getStoreUserList,getEnablePiecework } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { setStore, getStore } from "@/utils/store";

const state = {
  token: getToken(),
  userName: "",
  avatar: "",
  userInfo: getStore({ name: "userInfo" }) || {},
  menuList: JSON.parse(window.localStorage.getItem("storeMenuList")) || [],
  enablePiecework: JSON.parse(window.localStorage.getItem("enablePiecework")),
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.userName = name;
  },
  SET_STORE_NAME: (state, storeName) => {
    state.storeName = storeName;
  },
  SET_PHONE: (state, phone) => {
    state.phone = phone;
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
    setStore({ name: "userInfo", content: userInfo });
  },
  SET_USER_MENU: (state, menuList) => {
    state.menuList = menuList;
    window.localStorage.setItem("storeMenuList", JSON.stringify(menuList));
  },
  SET_ENABLE_PIECEWORK: (state, enablePiecework) => {
    state.enablePiecework = enablePiecework;
    window.localStorage.setItem("enablePiecework", JSON.stringify(enablePiecework));
  },
};


const actions = {
  // user login
  login({ commit }, userInfo) {
    console.log(userInfo);
    return new Promise((resolve, reject) => {
      login(userInfo.username.trim(), userInfo.password)
        .then((response) => {
          const { data } = response;
          commit("SET_TOKEN", data.access_token);
          setToken(data.access_token);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getStoreUserList({ commit }) {
    return new Promise((resolve, reject) => {
      getStoreUserList()
        .then((response) => {
          console.log(response);
          const { data } = response;
          if (!data) {
            reject("Verification failed, please Login again.");
          }
          let menuList = data.data?.map((item) => item.path);

          commit("SET_USER_MENU", [...menuList, "/","/login", "/home/get"]);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEnablePieceworkData({ commit }) {
    return new Promise((resolve, reject) => {
      getEnablePiecework()
        .then((response) => {
          const { data } = response;
          if (!data) {
            reject("Verification failed, please Login again.");
          }
          commit("SET_ENABLE_PIECEWORK", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // get user info
  getInfo({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!data) {
            reject("Verification failed, please Login again.");
          }
          commit("SET_USER_INFO", data.data);
          dispatch("getStoreUserList");
          dispatch("getEnablePieceworkData");
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          commit("SET_TOKEN", "");
          commit("SET_NAME", "");
          commit("SET_USER_INFO", {});
          resolve();
          removeToken();
          window.localStorage.removeItem("storeMenuList");
          window.localStorage.removeItem("enablePiecework");
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      removeToken();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
