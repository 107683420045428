export default {
    '000': '操作太频繁，请勿重复请求',
    '403': '当前操作没有权限',
    '404': '访问的资源不存在',
    '417': '未绑定登录账号，请使用密码登录后绑定',
    '422': '用户名不存在或密码错误',
    '429': '请求过频繁',
    '479': '演示环境，没有权限操作',
    '500': '系统未知错误,请反馈给总部技术人员',
    '503': '访问的服务无法连接,请稍后再试',
    'default': '系统未知错误,请反馈给管理员'
}
