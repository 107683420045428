import request from "@/utils/request";

export function login(username, password) {
  return request({
    url: "/auth/oauth/token?grant_type=password&scope=server&userType=store",
    method: "post",
    headers: {
      Authorization: "Basic c3RvcmU6MTIzNDU2",
    },
    params: { username, password },
  });
}

export function logout() {
  return request({
    url: '/auth/token/logout',
    method: 'delete'
  })
}

export function getInfo() {
  return request({
    url: "/sys/storeUser/currentLoginUserInfo",
    method: "get",
  });
}

/**
 * 查询所有的用户
 */
export function getUserPage(params) {
  return request({
    url: "/member/user",
    method: "get",
    params: params,
  });
}
// 门店用户修改密码
export function updatePassWord(data) {
  return request({
    url: "/sys/storeUser/update/passWord",
    method: "post",
    data,
  });
}
// 查询门店用户菜单集合
export function getStoreUserList() {
  return request({
    url: "/sys/storeUser/menu_list",
    method: "get",
  });
}
// 查询门店是否开启计件制提成
export function getEnablePiecework() {
  return request({
    url: "/sys/commissionRule/store/enable_piecework",
    method: "get",
  });
}
