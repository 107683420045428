import {getAccessToken} from '@/api/file/speech'

export default {
    async btts(text) {
        let res = await getAccessToken();
        let param = {
            tex: text,
            tok: res.data.data,
            spd: 4,
            pit: 5,
            vol: 15,
            per: 0
        };
        let url = 'https://tsn.baidu.com/text2audio';
        let p = param || {};
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        // 创建form参数
        let data = {};
        for (p in param) {
            data[p] = param[p]
        }
        // 赋值预定义参数
        data.cuid = data.cuid || data.tok;
        data.ctp = 1;
        data.lan = data.lan || 'zh';　　　 //以上为封装请求的参数
        // 序列化参数列表
        let fd = [];
        for (let k in data) {
            fd.push(k + '=' + encodeURIComponent(data[k]));
        }
        var frd = new FileReader();
        xhr.responseType = 'blob';
        xhr.send(fd.join('&'));　　　 //请求返回结果处理
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    if (xhr.response.type === 'audio/mp3') {　　　　　　　　　　　　  //创建audio对象进行播报
                        var audio = new Audio();
                        audio.setAttribute('src', URL.createObjectURL(xhr.response));
                        audio.play();
                        audio = null;
                    } else {　　　　　　　　　　　　//这里如果百度语音合成的接口返回类型不是audio/mp3，是json的话，代表你的参数有问题，返回结果失败，具体可以查看api文档
                        alert("调用语音合成接口token已失效!");
                    }
                } else {
                    alert("语音合成接口调用失败!");
                }
            }
        }
    },

}
