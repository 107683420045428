/**
 * @description 导出网络配置
 **/
module.exports = {
    // 正式项目可以选择自己配置成需要的接口地址，如"https://api.xxx.com"
    // 问号后边代表开发环境，冒号后边代表生产环境
    baseURL:
        process.env.NODE_ENV === 'development'
            ? '/xyt'
            : '/xyt',
    // 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
    contentType: 'application/json;charset=UTF-8',
    // 最长请求时间
    requestTimeout: 40000,
}
