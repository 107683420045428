import Vue from 'vue'
import { loadingText, messageDuration } from '@/config'
import { Loading, Message, MessageBox, Notification } from 'element-ui'


//复制到粘贴板插件
import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

import moment from 'moment'
// 日期插件
Vue.prototype.$moment = moment

// 引入全局过滤
Vue.filter('date', (val, format = 'YYYY-MM-DD') => moment(val).format(format))
Vue.filter('date1', res => {
    return moment(res).format('YYYY-MM-DD HH:mm:ss')
})

/**
 * @description 全局Notification
 * @param {string} message 说明文字
 * @param {string|VNode} title 标题
 * @param {'success'|'warning'|'info'|'error'} type 主题样式,如果不在可选值内将被忽略
 * @param {'top-right'|'top-left'|'bottom-right'|'bottom-left'} position 自定义弹出位置
 */
Vue.prototype.$baseNotify = (
    message,
    title,
    type = 'success',
    position = 'top-right',
    duration
) => {
    Notification({
        title,
        message,
        type,
        duration: duration || messageDuration,
        position,
    })
}
