import Vue from "vue";
import VueRouter from "vue-router";
import { publicPath, routerMode } from "@/config";
// import versionTood from '@/utils/versionUpdate.js'
Vue.use(VueRouter);

const Layout = () => import("@/layout");
const Get = () => import("@/view/get/index");
const GetQuery = () => import("@/view/get/getQuery");
const GetTypes = () => import("@/view/get/getTypes");
const PaySuccess = () => import("@/view/get/paySuccess");
const Charger = () => import("@/view/charger");
const ApplyCard = () => import("@/view/card");
const ApplyCardSuccess = () => import("@/view/card/applyCardSuccess");
const ChargerSuccess = () => import("@/view/charger/chargerSuccess");
const Order = () => import("@/view/order");
const User = () => import("@/view/user");
const More = () => import("@/view/more");
const Report = () => import("@/view/report");
const Complaint = () => import("@/view/complaint");
const TakeClothesIndex = () => import("@/view/takeClothes");
const TakeClothes = () => import("@/view/takeClothes/components/takeClothes");
const Clothes = () => import("@/view/takeClothes/components/clothes");
const AlreadyTakeClothes = () =>
  import("@/view/takeClothes/components/alreadyTake");
const SendWash = () => import("@/view/sendWash");
const EnterShop = () => import("@/view/enterShop");
const ShelvesIndex = () => import("@/view/shelves");
const Shelves = () => import("@/view/shelves/components/shelves");
const AlreadyShelves = () => import("@/view/shelves/components/alreadyShelves");
const DraftBox = () => import("@/view/draftbox");
const Reserve = () => import("@/view/reserve");
const Reminders = () => import("@/view/reminders");
const Refund = () => import("@/view/refund");
const Timeout = () => import("@/view/timeout");
const Rewashing = () => import("@/view/rewashing");
const Accounting = () => import("@/view/accounting");
const Flaw = () => import("@/view/flaw");
const ApplyCardList = () => import("@/view/card/applyCardList");
const WashDetails = () => import("@/view/washDetails");
const Login = () => import("@/view/login");
//配置衣物盘点
const Clothinginventory = () => import("@/view/clothinginventory");
const InventoryOperation = () =>
  import("@/view/clothinginventory/components/inventoryOperation");
const InventoryDetails = () =>
  import("@/view/clothinginventory/components/details");
const StoreManagerPoints = () => import("@/view/storeManagerPoints");
const ServicePoints = () =>
  import("@/view/storeManagerPoints/components/servicePoints.vue");
const ActionPoints = () =>
  import("@/view/storeManagerPoints/components/actionPoints.vue");
const ContributionPoints = () =>
  import("@/view/storeManagerPoints/components/contributionPoints.vue");
// 售后
const AfterSale = () => import("@/view/afterSale");
const backWashing = () => import("@/view/backWashing");
// 收衣拍照
const photoPage = () => import("@/view/get/components/photoPage.vue");
// 收支
const IncomeExpenses = () => import("@/view/incomeExpenses");
// 财务月账单详情页面
const AccountDetails = () => import("@/view/accounting/components/details");
// 财务账单明细
const transactionDetail = () =>
  import("@/view/accounting/components/transactionDetail");
// 卡设置
const cardSet = () => import("@/view/cardSet");
// 验券中心
const Meituan = () => import("@/view/meituan");
// 袋子管理
const bagManagement = () => import("@/view/logistics/bagManagement");
// 抖音验券
const tiktokCertificate = () => import("@/view/tiktok/tiktokCertificate");

// 库存查询
const stockInquiry = () => import("@/view/inventory/stockInquiry");
// 出入库明细
const intoAndOutDetails = () => import("@/view/inventory/components/intoAndOutDetails");
// 商品入库
const stockManagement = () => import("@/view/inventory/stockManagement");
// 商品出库
const outManagement = () => import("@/view/inventory/outManagement");
// 商品出入库详情
const commodityDetails = () =>
  import("@/view/inventory/components/commodityDetails");

// 出售明细
const sellDetails = () => import("@/view/inventory/components/sellDetails");

// 商品零售
const retailing = () => import("@/view/commodityRetailing/retailing");
// 采购管理
const purchase = () => import("@/view/commodityRetailing/purchase/index.vue");
// 采购进货
// const  stock =()=>import("@/view/commodityRetailing/purchase/index.vue");

// 我要采购
const myPurchase = () =>
  import("@/view/commodityRetailing/purchase/components/myPurchase");
// 我要采购
const productList = () =>
  import("@/view/commodityRetailing/retailing/components/productList");
// 商品订单
const commodityOrder = () => import("@/view/commodityRetailing/commodityOrder");
// 订单详情
const commodityOrderDetails = () => import("@/view/commodityRetailing/commodityOrder/components/orderDetails");

// 快速盘点
const FastCheck = () => import("@/view/fastCheck");
const FastCheckScanCode = () => import("@/view/fastCheck/components/scanCode");
const FastCheckDetails = () => import("@/view/fastCheck/components/fastCheckDetails");
// 退款订单
const refundOrder = () => import("@/view/order/refundOrder");
// 退款订单详情
const refundOrderDetails = () => import("@/view/order/refundOrder/components/refundDetails");

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "home",
    redirect: "/home/get",
    component: Layout,
    children: [
      {
        path: "/home/get",
        name: "get",
        component: Get,
        meta: {
          title: "收银台",
        },
      },
      {
        path: "/home/reserve",
        name: "reserve",
        component: Reserve,
        meta: {
          title: "预约",
        },
      },
      {
        path: "/home/more/reminders",
        name: "reminders",
        component: Reminders,
        meta: {
          title: "催单",
        },
      },
      {
        path: "/home/applyCard",
        name: "applyCard",
        component: ApplyCard,
        meta: {
          title: "办卡",
        },
      },
      {
        path: "/home/charger",
        name: "charger",
        component: Charger,
        meta: {
          title: "充值",
        },
      },
      {
        path: "/home/order",
        name: "order",
        component: Order,
        meta: {
          title: "订单",
        },
      },
      {
        path: "/home/refundOrder",
        name: "refundOrder",
        component: refundOrder,
        meta: {
          title: "退款订单",
        },
      },
      {
        path: "/home/refundOrderDetails",
        name: "refundOrderDetails",
        component: refundOrderDetails,
        meta: {
          title: "退款订单详情",
        },
      },
      {
        path: "/home/user",
        name: "user",
        component: User,
        meta: {
          title: "用户",
        },
      },
      {
        path: "/home/report",
        name: "report",
        component: Report,
        meta: {
          title: "汇总",
        },
      },
      {
        path: "/home/more",
        name: "more",
        component: More,
        meta: {
          title: "更多",
        },
      },
      {
        path: "/home/more/complaint",
        name: "complaint",
        component: Complaint,
        meta: {
          title: "投诉建议",
        },
      },
      {
        path: "/home/more/takeClothesIndex",
        name: "takeClothesIndex",
        component: TakeClothesIndex,
        meta: {
          title: "取衣主页",
        },
      },
      {
        path: "/home/more/clothes",
        name: "clothes",
        component: Clothes,
        meta: {
          title: "我要取衣",
        },
      },
      {
        path: "/home/more/takeClothes",
        name: "takeClothes",
        component: TakeClothes,
        meta: {
          title: "取衣",
        },
      },
      {
        path: "/home/more/clothinginventory",
        name: "clothinginventory",
        component: Clothinginventory,
        meta: {
          title: "衣物盘点",
        },
      },
      {
        path: "/home/more/inventoryOperation",
        name: "inventoryOperation",
        component: InventoryOperation,
      },
      {
        path: "/home/more/inventoryDetails",
        name: "inventoryDetails",
        component: InventoryDetails,
      },
      {
        path: "/home/more/StoreManagerPoints",
        name: "StoreManagerPoints",
        component: StoreManagerPoints,
        meta: {
          title: "店长分",
        },
      },
      {
        path: "/home/more/ServicePoints",
        name: "ServicePoints",
        component: ServicePoints,
      },
      {
        path: "/home/more/ActionPoints",
        name: "ActionPoints",
        component: ActionPoints,
      },
      {
        path: "/home/more/ContributionPoints",
        name: "ContributionPoints",
        component: ContributionPoints,
      },

      {
        path: "/home/more/alreadyTake",
        name: "alreadyTake",
        component: AlreadyTakeClothes,
        meta: {
          title: "已取衣列表",
        },
      },
      {
        path: "/home/more/sendWashTable",
        name: "sendWashTable",
        component: SendWash,
        meta: {
          title: "送洗列表",
        },
      },
      {
        path: "/home/more/sendWash",
        name: "sendWash",
        component: () => import("@/view/sendWash/washNav"),
        meta: {
          title: "送洗",
        },
      },
      {
        path: "/home/more/enterShop",
        name: "enterShop",
        component: EnterShop,
        meta: {
          title: "入店",
        },
      },
      {
        path: "/home/more/shelvesIndex",
        name: "shelvesIndex",
        component: ShelvesIndex,
        meta: {
          title: "上挂主页",
        },
      },
      {
        path: "/home/more/shelves",
        name: "shelves",
        component: Shelves,
        meta: {
          title: "我要上挂",
        },
      },
      {
        path: "/home/more/alreadyShelves",
        name: "alreadyShelves",
        component: AlreadyShelves,
        meta: {
          title: "已上挂列表",
        },
      },
      {
        path: "/home/more/draftbox",
        name: "draftBox",
        component: DraftBox,
        meta: {
          title: "草稿箱",
        },
      },
      {
        path: "/home/more/refund",
        name: "refund",
        component: Refund,
        meta: {
          title: "退款退赔",
        },
      },
      {
        path: "/home/more/rewashing",
        name: "rewashing",
        component: Rewashing,
        meta: {
          title: "衣物返洗",
        },
      },
      {
        path: "/home/more/timeout",
        name: "timeout",
        component: Timeout,
        meta: {
          title: "衣物超时",
        },
      },
      {
        path: "/home/more/flaw",
        name: "flaw",
        component: Flaw,
        meta: {
          title: "瑕疵订单",
        },
      },
      {
        path: "/home/accounting",
        name: "accounting",
        component: Accounting,
        meta: {
          title: "收入收支",
        },
      },
      {
        path: "/home/more/applyCardList",
        name: "applyCardList",
        component: ApplyCardList,
        meta: {
          title: "办卡列表",
        },
      },
      {
        path: "/home/todayData/todayNetReceipts",
        name: "todayNetReceipts",
        component: () => import("@/view/todayData/todayNetReceipts"),
        meta: {
          title: "实收列表",
        },
      },
      {
        path: "/home/todayData/todayRechange",
        name: "todayRechange",
        component: () => import("@/view/todayData/todayRechange"),
        meta: {
          title: "充值列表",
        },
      },
      {
        path: "/home/todayData/todayNewCard",
        name: "todayNewCard",
        component: () => import("@/view/todayData/todayNewCard"),
        meta: {
          title: "办卡列表",
        },
      },
      {
        path: "/home/todayData/todayRefund",
        name: "todayRefund",
        component: () => import("@/view/todayData/todayRefund"),
        meta: {
          title: "退款列表",
        },
      },
      {
        path: "/home/more/washDetails",
        name: "washDetails",
        component: WashDetails,
        meta: {
          title: "洗涤明细",
        },
      },
      {
        path: "/home/myCommission",
        name: "myCommission",
        component: () => import("@/view/myCommission"),
        meta: {
          title: "我的提成明细",
        },
      },
      {
        path: "/home/inService",
        name: "inService",
        component: () => import("@/view/inService"),
        meta: {
          title: "服务中衣物列表",
        },
      },
      {
        path: "/home/storeCommission",
        name: "storeCommission",
        component: () => import("@/view/storeCommission"),
        meta: {
          title: "门店提成",
        },
      },
      {
        path: "/home/more/afterSale",
        name: "afterSale",
        component: AfterSale,
        meta: {
          title: "售后",
        },
      },
      {
        path: "/home/more/backWashing",
        name: "backWashing",
        component: backWashing,
        meta: {
          title: "退洗",
        },
      },
      {
        path: "/home/incomeExpenses",
        name: "incomeExpenses",
        component: IncomeExpenses,
      },
      {
        path: "/home/accountDetails",
        name: "accountDetails",
        component: AccountDetails,
      },

      {
        path: "/home/transactionDetail",
        name: "transactionDetail",
        component: transactionDetail,
      },
      {
        path: "/home/more/cardSet",
        name: "cardSet",
        component: cardSet,
      },
      {
        path: "/home/more/Meituan",
        name: "Meituan",
        component: Meituan,
      },
      {
        path: "/home/more/bagManagement",
        name: "bagManagement",
        component: bagManagement,
      },
      {
        path: "/home/more/tiktokCertificate",
        name: "tiktokCertificate",
        component: tiktokCertificate,
      },
      {
        path: "/home/more/stockInquiry",
        name: "stockInquiry",
        component: stockInquiry,
      },
      {
        path: "/home/more/intoAndOutDetails",
        name: "intoAndOutDetails",
        component: intoAndOutDetails,
      },
      {
        path: "/home/more/outManagement",
        name: "outManagement",
        component: outManagement,
      },
      {
        path: "/home/more/stockManagement",
        name: "stockManagement",
        component: stockManagement,
      },
      {
        path: "/home/more/commodityDetails",
        name: "commodityDetails",
        component: commodityDetails,
      },
      {
        path: "/home/more/sellDetails",
        name: "sellDetails",
        component: sellDetails,
      },
      {
        path: "/home/more/purchase",
        name: "purchase",
        component: purchase,
      },
      {
        path: "/home/more/myPurchase",
        name: "myPurchase",
        component: myPurchase,
      },
      {
        path: "/home/more/commodityOrder",
        name: "commodityOrder",
        component: commodityOrder,
      },
      {
        path: "/home/more/commodityOrderDetails",
        name: "commodityOrderDetails",
        component: commodityOrderDetails,
      },
        // 快速盘点
      {
        path: "/home/more/fastCheck",
        name: "fastCheck",
        component: FastCheck,
      },
      {
        path: "/home/more/scanCode",
        name: "scanCode",
        component: FastCheckScanCode,
      },
      {
        path: "/home/more/fastCheckDetail",
        name: "fastCheckDetail",
        component: FastCheckDetails,
      },

    ],
  },
  {
    path: "/get/query",
    name: "query",
    component: GetQuery,
  },
  {
    path: "/get/types",
    name: "types",
    component: GetTypes,
  },
  {
    path: "/get/paySuccess",
    name: "paySuccess",
    component: PaySuccess,
  },
  {
    path: "/applyCard/applyCardSuccess",
    name: "applyCardSuccess",
    component: ApplyCardSuccess,
  },
  {
    path: "/charger/chargerSuccess",
    name: "chargerSuccess",
    component: ChargerSuccess,
  },
  {
    path: "/get/photoPage",
    name: "photoPage",
    component: photoPage,
  },
  {
    path: "/home/more/retailing",
    name: "retailing",
    component: retailing,
  },
  {
    path: "/home/more/productList",
    name: "productList",
    component: productList,
  },
];
const createRouter = () =>
  new VueRouter({
    base: publicPath,
    mode: routerMode,
    scrollBehavior: () => ({ y: 0 }),
    routes: routes,
  });

const router = createRouter();

// 解决两次访问相同路由地址报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
