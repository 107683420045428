import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import errorCode from '@/const/errorCode'
import {getStore} from "@/utils/store";
import {getToken} from '@/utils/auth'
import store from '@/store'
import router from "../router/router"
import {
    baseURL,
    requestTimeout
} from '@/config'


// 提示信息显示时长
const messageDuration = 5 * 1000

const service = axios.create({
    baseURL,
    timeout: requestTimeout,
})

service.interceptors.request.use(
    config => {
        const TENANT_ID = getStore({name: 'tenantId'})
        if (TENANT_ID) {
            config.headers['TENANT_ID'] = TENANT_ID // 租户ID
        }
        if (getToken()) {
            config.headers['Authorization'] = 'Bearer ' + getToken() // jwt
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        return response
    },
    error => {
        console.log(error.response)
        let _this = this;
        const status = Number(error.response.status) || 200
        const errorMessage = errorCode[status] || error.response.data.msg || errorCode['default']
        console.log(status, errorMessage)
        if (status === 401 && errorMessage === "无效的token") {
            MessageBox.confirm(
                '登录状态已过期，请重新登录',
                '系统提示',
                {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                store.dispatch('user/logout').then(() => {
                    location.reload() //重新实例化vue-router对象
                })
            })
        } else {
            Message({
                message: errorMessage,
                type: 'error',
                duration: messageDuration
            })
        }
        return Promise.reject(error)
    }
)

export default service
